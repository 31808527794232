.presentation {
    padding-bottom: 4rem;
    position: relative;
    height: 680px;
    background-color: #34375a75!important;
    background-image: linear-gradient(#111827, #34375a75);
    h1 {
        font-family: 'Lilita One', cursive;
    }
    > div {
        z-index: 5;
    }
}

.laptopEmoji {
    display: block;
    font-size: 33px;
    position: relative;
    top: -4px;
    left: 5px;
}

.icon {
    svg {
        width: 50px;
        height: 50px;
        fill: #c084fc;
        transition: top .3s;
        position: relative;
        top: 0;
    }
    &:hover {
        svg {
            top: 5px;
        }
    }
}